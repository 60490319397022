import {
  AppService,
  ComponentService,
  IPlatmaAppComponent,
  PlRender,
} from '@mf-ide/common';

export function SetVariableActionHandler(
  render: PlRender,
  screen: IPlatmaAppComponent,
  component: ComponentService,
  params: { level: string; variable: string; value: string; component: string },
  data?: any,
  data_index?: number,
) {
  if (params.level === 'app') {
    AppService.SetVariable(params.variable, params.value);
  }
  if (params.level === 'page') {
    AppService.getScreen(screen.id).SetVariable(
      params.variable,
      params.value,
      data,
      data_index,
    );
  }
  if (params.level === 'component' && params.component) {
    const s = AppService.getScreen(screen.id);
    if (s) {
      const c = s.Component(params.component);
      if (c) {
        c.SetVariable(params.variable, params.value);
      }
    }
  }
}
