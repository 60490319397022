import { BaseLayer, ComponentService } from './';
import { PLATMA_DESIGN_COMPONENT_LOCKED_ATTRIBUTE } from '../constants';
import { EventPrefix, IVariableEvent, VARIABLE_SET_EVENT } from '../events';

export class HtmlLayer extends BaseLayer {
  public htmlElement: HTMLElement | undefined;
  protected parentElement: HTMLElement | undefined = undefined;
  private observer: MutationObserver | undefined;

  public InsertIntoParentBeforeChild(childId: string) {
    if (!this.htmlElement) return;
    if (this.htmlElement.parentElement) return;
    if (this.parentElement && this.component) {
      const re = this.parentElement.querySelector(`:scope>#${childId}`);
      if (re && this.htmlElement) {
        re.before(this.htmlElement);
      }
    }
  }

  protected AppendToParent() {
    if (!this.htmlElement) return;
    if (this.parentElement && this.component) {
      const e = this.parentElement.querySelector(
        `:scope>${this.component.structure.tag}#${this.component.id}`,
      );
      if (!e && this.htmlElement) {
        this.parentElement.append(this.htmlElement);
      }
    }
  }

  protected Delete() {
    if (this.observer) this.observer.disconnect();
    if (this.htmlElement) {
      this.htmlElement.remove();
      delete this.htmlElement;
    }
    this.ExecuteDeleteCallback();
    super.Delete();
  }

  public InsertAfterMyself(c: ComponentService) {
    if (this.htmlElement && c.htmlElement) {
      this.htmlElement.after(c.htmlElement);
    }
  }

  public ExecuteRenderCallback() {
    if (this.screen && this.component && this.screen.elementRenderCallback) {
      this.screen.elementRenderCallback(
        this.htmlElement as HTMLElement,
        this.component.structure,
      );
    }
  }

  public ExecuteDeleteCallback() {
    if (this.screen && this.component && this.screen.elementDeleteCallback) {
      this.screen.elementDeleteCallback(
        this.htmlElement as HTMLElement,
        this.component.structure,
      );
    }
  }

  protected OnVariableChange(detail: IVariableEvent) {
    // do nothing for now
  }

  private variableChangeHandler = (e: Event) => {
    const detail = (e as CustomEvent<IVariableEvent>).detail;
    if (this.screen && detail.screenId && detail.screenId !== this.screen.id) {
      return;
    }
    this.OnVariableChange(detail);
  };

  public CreateElement() {
    if (!this.screen) return;
    if (!this.component) return;
    if (!this.htmlElement) {
      const t = this.structure.tag;
      if (t) {
        this.htmlElement = document.createElement(t);
        document.addEventListener(
          EventPrefix + VARIABLE_SET_EVENT,
          this.variableChangeHandler,
        );
        this.ExectureBeforeCreate();
        if (
          this.component.data_index < 1 &&
          this.screen.elementCreatedCallback
        ) {
          this.screen.elementCreatedCallback(
            this.htmlElement as HTMLElement,
            this.component.structure,
          );
        }
        this.ExectureAfterCreate();
        this.observer = new MutationObserver((mutationList, observer) => {
          for (const mutation of mutationList) {
            if (mutation.attributeName) {
              this.AttributeChanged(mutation.attributeName);
            }
          }
        });
        this.observer.observe(this.htmlElement, { attributes: true });
      }
    }
  }

  protected AttributeChanged(attributeName: string) {
    // absctract method for now
  }

  public ExectureBeforeCreate() {
    if (
      this.htmlElement &&
      this.component &&
      this.component.config &&
      this.component.config.beforeCreate
    ) {
      this.component.config.beforeCreate(this.htmlElement, this.component);
    }
  }

  public ExectureAfterCreate() {
    if (
      this.htmlElement &&
      this.component &&
      this.component.config &&
      this.component.config.afterCreate
    ) {
      this.component.config.afterCreate(this.htmlElement, this.component);
    }
  }

  public SetElement(element: HTMLElement) {
    this.htmlElement = element;
  }

  public SetParentElement(parentElement: HTMLElement) {
    this.parentElement = parentElement;
  }

  public GetAttribute(name: string, defaultValue: string): string {
    if (this.htmlElement && this.htmlElement.hasAttribute(name)) {
      const r = this.htmlElement.getAttribute(name);
      if (r) return r;
    }
    return super.GetConfigAttribute(name, defaultValue);
  }

  public SetAttribute(name: string, value: string) {
    if (this.htmlElement) {
      if (value && value.trim()) {
        this.htmlElement.setAttribute(name, value);
      } else {
        this.htmlElement.removeAttribute(name);
      }
    }
  }

  public SetStyle(name: string, value: string) {
    if (this.structure) {
      this.SetStyles(this.structure.style);
    }
  }

  public SetContent(content: string) {
    if (!this.htmlElement) return;
    if (!this.component) return;
    if (this.config.setContent) {
      this.config.setContent(this.htmlElement, this.component, content);
    } else {
      this.htmlElement.innerHTML = content;
    }
  }

  //TODO: refactor
  public SetStyles(style: { [index: string]: string } = {}) {
    if (!this.htmlElement || !style) {
      return;
    }
    this.htmlElement.setAttribute(
      'style',
      Object.keys(style)
        .filter((k) => {
          return style[k] !== '' && style[k] !== null && style[k] !== undefined;
        })
        .map((s) => {
          if (
            !(s in style) ||
            !style[s] ||
            !(typeof style[s] === 'string') ||
            style[s].trim() === ''
          ) {
            return null;
          }
          return s + ':' + style[s];
        })
        .join(';'),
    );
  }

  protected UpdateLockAttribute() {
    if (this.htmlElement && this.component) {
      this.htmlElement.setAttribute(
        PLATMA_DESIGN_COMPONENT_LOCKED_ATTRIBUTE,
        this.component.structure.locked ? 'true' : 'false',
      );
    }
  }
}
