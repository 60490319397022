import {
  AppService,
  ComponentService,
  ExpressionLayer,
  IPlatmaAppComponent,
  PlRender,
} from '@mf-ide/common';

export function RunJsHandler(
  render: PlRender,
  screen: IPlatmaAppComponent,
  component: ComponentService,
  params: { code: string },
  data?: any,
  data_index?: number,
) {
  const vars: any = AppService.GetVariables(screen.id);
  vars.data = data;
  vars.data_index = data_index;
  vars.element = component.htmlElement;
  ExpressionLayer.EvaluateCode(params.code, vars);
}
