import { pubVariableSet } from '../events';
import { ExpressionLayer } from './ExpressionLayer';
import { AppService } from './AppService';
import { proxify } from './proxify';
import { IPlatmaAppComponent } from '../interfaces/component/IPlatmaAppComponent';
import { IComponentsBundleItem } from '../interfaces/bundles/IComponentsBundle';
import { IPlatmaAppScreenType } from '../interfaces/screen/IPlatmaAppScreenType';

export type TVariableLevel = 'app' | 'page' | 'component';

export class VariablesLayer extends ExpressionLayer {
  protected variables: { [index: string]: any } = {};
  protected variablesLevel: TVariableLevel;

  constructor(
    public screenId: string,
    public structure: IPlatmaAppComponent,
    public config: IComponentsBundleItem,
  ) {
    super(screenId, structure, config);

    let prefix = '';

    switch (structure.type) {
      case IPlatmaAppScreenType.PAGE:
      case IPlatmaAppScreenType.MODAL:
      case IPlatmaAppScreenType.COMPONENT:
        this.variablesLevel = 'page';
        prefix = 'page';
        break;
      default:
        this.variablesLevel = 'component';
        prefix = "components."+this.structure.id
    }

    this.variables = proxify(
      this.InitComponentVariables(),
      (_: any, name: string, oldValue: any, newValue: any) => {
        switch (this.variablesLevel) {
          case 'page':
            this.pubSet(name, newValue);
            break;
          case 'component':
            break;
        }
      },
      prefix,
    );
  }

  protected AttributeChanged(attributeName: string) {
    if(!this.config || !this.config.variables) return
    const vc = this.config.variables.find(v => v.bindName === attributeName && v.bind==="attribute")
    if(vc) {
      const av = this.GetAttribute(vc.name, "")
      this.SetVariable(vc.name, av)
    }
  }

  private pubSet(name: string, value: string) {
    pubVariableSet({
      level: this.variablesLevel,
      screenId: this.screen?.id ?? '',
      componentId: this.component?.id ?? '',
      name: name,
      value: value,
    });
  }

  public GetVariables(): { [index: string]: any } {
    return { ...this.variables };
  }

  public SetVariable(
    name: string,
    value: any,
    data?: any,
    data_index?: number,
  ) {
    if (!this.screen) return;
    switch (this.variablesLevel) {
      // case 'app':
      //   this.variables[name] = value;
      //   break;
      case 'page':
        const pvars: any = AppService.GetVariables(this.screen.id);
        if (data) {
          pvars['data'] = data;
          pvars['data_index'] = data_index;
        }
        const nv = ExpressionLayer.Test(value)
          ? ExpressionLayer.Eval(value, pvars)
          : value;
        this.screen.variables.variables[name] = nv;
        break;
      case 'component':
        if (this.config && this.config.variables) {
          this.config.variables.forEach((v) => {
            if (this.screen && v.name === name) {
              if (this.variables[name] != value) {
                this.variables[name] = value;
                this.pubSet(name, value);
              }
            }
          });
        }
        break;
    }
  }

  /**
   * One way syncronization from component structure into variables
   */
  protected InitComponentVariables() {
    const vars: any = {};
    if (this.structure && this.config && this.config.variables) {
      this.config.variables.forEach((v) => {
        switch (v.bind) {
          case 'attribute':
            if (v.bindName && this.structure.attributes) {
              let value = this.structure.attributes[v.bindName]
              if(ExpressionLayer.Test(value)) {
                value = this.EvaluateSingleValue(value)
              }
              vars[v.name] = value ?? '';
            }
            break;
          case 'innerHtml':
            if ('innerHtml' in this.structure) {
              vars[v.name] = this.structure.innerHtml;
            }
            break;
          case "method":
            if(v.name) {
              vars[v.name] = (...args:any[]) => {
                if(this.htmlElement) {
                  // @ts-ignore
                  this.htmlElement[v.name](args)
                }
              }
            }
        }
      });
    }
    return vars;
  }
}
