import { IPlatmaUiAppVersion } from '../interfaces/app/IPlatmaUiAppVersion';
import { ApiService, QueryService, ScreenService } from './';
import { IPlatmaApp, IPlatmaAppSettings } from '../interfaces/IPlatmaApp';
import { pubScreenAdded, pubScreenDeleted, pubVariableSet } from '../events';
import { IPlatmaAppScreenType } from '../interfaces/screen/IPlatmaAppScreenType';
import { IPlatmaUiApp } from '../interfaces/app/IPlatmaUiApp';
import { IVariablesRoot } from '../interfaces/variables/IVariablesRoot';
import { IPlatmaAppComponent } from '../interfaces/component/IPlatmaAppComponent';

export class AppService {
  private static baseUrl: string;
  private static name: string;
  private static instance: AppService;
  private static versions: IPlatmaUiAppVersion[];
  private static version: IPlatmaUiAppVersion;
  private static appID: string;
  private screenServices: { [index: string]: ScreenService } = {};
  private activeScreenId: string | null = null;
  private readonly settings: IPlatmaAppSettings;

  public variables: any;

  constructor(private app: IPlatmaApp) {
    if (!app['screens']) {
      app['screens'] = [];
    }
    if (!app.applicationSettings) {
      app.applicationSettings = {};
    }
    this.settings = app.applicationSettings;

    this.variables = new Proxy(
      {},
      {
        get(target: object, property: string, receiver: unknown) {
          return Reflect.get(target, property, receiver);
        },
        defineProperty(
          target: object,
          property: string,
          descriptor: PropertyDescriptor,
        ) {
          return Reflect.defineProperty(target, property, descriptor);
        },
        deleteProperty(target: object, property: string) {
          return Reflect.deleteProperty(target, property);
        },
      },
    );

    this.app.screens.forEach((s) => {
      const ns = new ScreenService(s);
      this.screenServices[s.id] = ns;
      ns.SetScreenService(ns);
      if (Array.isArray(s.children)) ns.InitChildren(s);
    });
  }

  public static GetBaseURL() {
    return this.baseUrl;
  }

  public static SetBaseURL(v: string) {
    this.baseUrl = v;
  }

  public static GetID() {
    return this.appID;
  }

  public static GetName() {
    return this.name;
  }

  public static SetName(name: string) {
    this.name = name;
  }

  public static GetSettings(): IPlatmaAppSettings {
    return this.instance.settings;
  }

  public static GetVariables(screenId: string): IVariablesRoot {
    const s = this.getScreen(screenId);
    return {
      components: { ...s.GetComponentVariables() },
      page: { ...s.GetVariables() },
      queries: QueryService.GetVariables(),
      variables: { ...this.instance.variables },
    };
  }

  public static SetVariable(name: string, value: any) {
    this.instance.variables[name] = value;
    pubVariableSet({
      level: 'app',
      name,
      value,
      screenId: null,
      componentId: null,
    });
  }

  public static getVersion() {
    return this.version;
  }

  public static getApp() {
    if (!this.instance || !this.instance.app) return;
    return this.instance.app;
  }

  public static SetActiveScreenID(screenId: string | null) {
    this.instance.activeScreenId = screenId;
  }

  public static GetActiveScreen(): ScreenService | null {
    if (
      this.instance.activeScreenId &&
      this.instance.activeScreenId in this.instance.screenServices
    ) {
      return this.instance.screenServices[this.instance.activeScreenId];
    }
    return null;
  }

  public static getScreen(screenId: string): ScreenService {
    if (screenId in this.instance.screenServices) {
      return this.instance.screenServices[screenId];
    }
    throw new Error(`Screen ${screenId} not found`);
  }

  public static deleteScreen(screenId: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const i = this.instance.app.screens.findIndex((s) => s.id == screenId);
      if (i >= 0) {
        this.instance.app.screens.splice(i, 1);
        this.Save()
          .then(() => {
            pubScreenDeleted(screenId);
            resolve(true);
          })
          .catch(() => resolve(false));
      } else {
        resolve(false);
      }
    });
  }

  public static FindScreenBySlug(
    slug: string,
  ): IPlatmaAppComponent | undefined {
    if (this.instance.app.screens.length == 1) {
      return this.instance.app.screens[0];
    }
    return this.instance.app.screens
      .filter((s) => s.type == IPlatmaAppScreenType.PAGE)
      .find((s) => {
        if (s.settings) {
          if (slug.trim() === '/') return s.settings.isDefaultPage;
          if (slug.trim() != '') {
            return s.settings.slug == slug || '/' + s.settings.slug == slug;
          }
          return s.settings.isDefaultPage;
        }
        return false;
      });
  }

  public static async AddScreen(newscreen: IPlatmaAppComponent) {
    if (!newscreen.settings) return;
    if (
      this.instance.app.screens.filter(
        (s) => s.type === IPlatmaAppScreenType.PAGE,
      ).length == 0
    ) {
      newscreen.settings.isDefaultPage = true;
    }
    this.instance.app.screens.push(newscreen);
    this.instance.screenServices[newscreen.id] = new ScreenService(newscreen);
    pubScreenAdded(newscreen.id);
    return this.Save();
  }

  public static async Init(appID: string) {
    this.appID = appID;
    return this.Reload();
  }

  public static InitStatic(app: IPlatmaApp) {
    this.appID = 'app1';
    this.instance = new AppService(app);
  }

  public static Reload(): Promise<IPlatmaUiApp> {
    return new Promise<IPlatmaUiApp>((resolve, reject) => {
      ApiService.GetApp(this.appID)
        .then((r) => {
          this.version = r.data.editing_version;
          this.instance = new AppService(this.version.definition);
          AppService.SetName(r.data.name);
          resolve(r.data);
        })
        .catch(reject);
    });
  }

  public static ReloadVersions(): Promise<IPlatmaUiAppVersion[]> {
    return new Promise<IPlatmaUiAppVersion[]>((resolve, reject) => {
      ApiService.GetVersions(this.appID)
        .then((r) => {
          this.versions = r.data.versions;
          resolve(this.versions);
        })
        .catch(reject);
    });
  }

  public static async Save() {
    return ApiService.SaveVersion(
      this.appID,
      this.version.id,
      this.instance.app,
    );
  }
}
