import { AppService } from '../services';
import { PlApp } from './pl-app';
import { dc } from './tools';

//language=css
const css = `
  pl-default-layout-sidebar {
      flex-shrink: 0;
      box-sizing: border-box;
      display: flex;
      position: relative;
      width: 200px;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;
      background-color: transparent;
      height: calc(100% - 3rem);
  }
  pl-default-layout-sidebar > a {
      box-sizing: border-box;
      width: 100%;
      text-align: right;
      color:var(--pl-color-text);
      background-color: var(--pl-color-default-hover);
      text-decoration: none;
      padding: .5rem 1rem;
      border-radius: .25rem;
  }
  html[pl-color-scheme=light] {
      pl-default-layout-sidebar > a.current {
          background-color: #e6edfe;
      }
  }
  html[pl-color-scheme=dark] {
      pl-default-layout-sidebar > a.current {
          background-color: #1c274f;
      }
  }
  @media (max-width: 768px) {
      pl-default-layout-sidebar {
          background-color: var(--pl-color-body);
          position: absolute;
          top:3rem;
          left: -200px;
      }
      pl-default-layout-sidebar[open] {
          left:0;
      }
  }

`

export class PlDefaultLayoutSidebar extends HTMLElement {

  // noinspection JSUnusedGlobalSymbols
  connectedCallback() {
    const cs = AppService.GetActiveScreen()
    if(cs) {
      this.activeScreenID = cs.id
    }
    this.render()
  }

  private render() {
    this.innerHTML = ""
    const app = AppService.getApp()
    if(app) {
      app.screens.forEach(s => {
        if(s.settings) {
          const a = dc("a") as HTMLLinkElement
          a.innerHTML = s.settings.title
          a.href = AppService.GetBaseURL()+"/"+s.settings.slug
          if(s.id == this.activeScreenID) {
            a.className="current"
          }
          a.onclick = e => {
            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()
            if(s.settings) {
              const plapp = this.closest("pl-app") as PlApp
              if(plapp) {
                this.removeAttribute("open")
                plapp.Navigate(s.settings.slug)
              }
            }
            return false
          }
          this.append(a)
        }
        const style = dc("style")
        style.innerHTML = css
        this.append(style)
      })
    }
  }

  private _activeScreenID:string = ""
  public set activeScreenID(id:string) {
    this._activeScreenID = id
    this.render()
  }
  public get activeScreenID() {
    return this._activeScreenID
  }

}

if (!customElements.get('pl-default-layout-sidebar')) {
  customElements.define('pl-default-layout-sidebar', PlDefaultLayoutSidebar);
}
