// @ts-ignore
import axios, {AxiosInstance, AxiosResponse} from "axios";
import {IAPIAppsResponse} from "../interfaces/api/apps";
import {IAPIGerVersions} from "../interfaces/app/IPlatmaUiAppVersion";
import {IPlatmaUiApp} from "../interfaces/app/IPlatmaUiApp";
import {IPlatmaApp} from "../interfaces/IPlatmaApp";
import {IAPIGetQueries, IQuery} from "../interfaces/query/IQuery";

export interface LoginFields {
  login: string;
  password: string;
}

// export interface RegisterFields {
//   firstName?: string;
//   lastName?: string;
//   email: string;
//   password: string;
// }

export interface ILoginResponse {
  token: {
    access_token: string
  }
}

// export interface ILoginErrorResponse {
//   message: string;
//   statusCode: number;
// }

export class ApiService {

  private static instance: ApiService
  private readonly api: AxiosInstance

  constructor(private apiUrl:string, private authImplicit: boolean) {
    this.api = axios.create({
      baseURL: this.apiUrl,
      headers: { "Content-Type": "application/json" },
      withCredentials: true
    });

    let isRefreshing = false;
    let refreshPromise: Promise<any> | null = null;

    this.api.interceptors.request.use(
        (config: any) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }

        return config;
      },
        (error: Error) => {
        return Promise.reject(error);
      }
    );

    this.api.interceptors.response.use(
      (response: any) => response,
      async (error: { config: any; response: { status: number; }; }) => {
        const prevRequest = error?.config;

        if (error?.response?.status === 401 && !prevRequest?.retry) {
          prevRequest.retry = true;

          if (!isRefreshing) {
            isRefreshing = true;
            refreshPromise = this.refreshTokenRequest();

            await refreshPromise;
            refreshPromise = null;
            isRefreshing = false;

            return this.api(prevRequest);
          } else {
            await refreshPromise;
            return this.api(prevRequest);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  private async refreshTokenRequest() {
    return await this.api.post('/authorization/refreshToken');
  }

  public static Init(apiUrl:string, authImplicit: boolean) {
    ApiService.instance = new ApiService(apiUrl,authImplicit)
  }

  public static async Login(fields:LoginFields) {
    const response = await this.instance.api.post<ILoginResponse>('/authorization/token', fields);

    const token = response?.data?.token;
    if (token) {
      localStorage.setItem('sourceWaitingForOAuth', 'orgIdForOauth');
      // setCookie('orgIdForOauth', String(token?.userId), 100);

      if (this.instance.authImplicit) {
        localStorage.setItem('token', token.access_token);
      }
    }

    return response.data;
  }

  public static async GetApps() {
    return this.instance.api.get<IAPIAppsResponse>(`/apps`, {
      params: {
        page: 0,
        filter: JSON.stringify({}),
      },
    });
  }

  public static async GetVersions(appId: string): Promise<AxiosResponse<IAPIGerVersions>> {
    return this.instance.api.get(`/apps/${appId}/versions`);
  }

  public static async GetApp(id:string): Promise<AxiosResponse<IPlatmaUiApp>> {
    return this.instance.api.get(`/apps/${id}`)
  }

  public static async SaveVersion(appId:string, versionId:number, definition:IPlatmaApp, isUserSwitchedVersion = false) {
    return this.instance.api.put(`/apps/${appId}/versions/${versionId}`,{is_user_switched_version: isUserSwitchedVersion,definition})
  }

  public static async GetUser() {
    return this.instance.api.get('/users/me')
  }

  public static async GetQueries(appId:string): Promise<AxiosResponse<IAPIGetQueries>> {
    return this.instance.api.get(`/data_queries?app_id=${appId}`)
  }

  public static async SaveQuery(query:IQuery): Promise<AxiosResponse<IQuery>> {
    return this.instance.api.patch(`/data_queries/${query.id}`,query)
  }
}
