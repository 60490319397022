import { publishEvent } from './publishEvent';

export const VARIABLE_SET_EVENT = 'app-variable-set';
// export const VARIABLE_DEL_EVENT = "variable-del"

export interface IVariableEvent {
  level: "app"|"page"|"component"|"query"
  screenId: string|null
  componentId:string|null
  name: string
  value: any
}

export function pubVariableSet(detail:IVariableEvent) {
  publishEvent(VARIABLE_SET_EVENT, detail)
}

