//language=css
export const RuntimeStyles = `
    pl-render {
        &[disable-visibility-emulation] *[hide-lg] {
            display: inherit;
        }
        &[disable-visibility-emulation] *[hide-md] {
            display: inherit;
        }
        &[disable-visibility-emulation] *[hide-sm] {
            display: inherit;
        }
        *[pl-dynamically-hidden] {
            display: none;
        }
        &[disable-visibility-emulation] *[pl-dynamically-hidden] {
            display: inherit;
        }
    }
`;
//language=css
export const RuntileStylesMediaQuery = `
    @media (max-width: 400px) {
        pl-render *[hide-sm] {
            display: none;
        }
    }
    @media (min-width: 401px) and (max-width: 768px) {
        pl-render *[hide-md] {
            display: none;
        }
        pl-render[disable-media-query] *[hide-md]{
            display: inherit;
        }
    }
    @media (min-width: 769px) {
        pl-render *[hide-lg] {
            display: none;
        }
    }
`