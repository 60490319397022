import { IPlatmaAppComponent } from '../../interfaces/component/IPlatmaAppComponent';
import { ComponentService } from '../../services';
import { PlRender } from '../../render';

export function OpenModal(
  render: PlRender,
  screen: IPlatmaAppComponent,
  component: ComponentService,
  params: { modalId: string },
) {
  render.renderModal(params.modalId);
}
