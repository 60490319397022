import { IPlatmaAppComponent } from '../interfaces/component/IPlatmaAppComponent';

export const COMPONENT_INSERTED_EVENT = 'component-inserted';
export const COMPONENT_SELECTED_EVENT = "component-selected"
export const COMPONENT_DELETED_EVENT = "component-deleted"
export const COMPONENT_MOVED_EVENT = "component-moved"
export const COMPONENT_CONFIGURATION_CHANGED_EVENT = "component-configuration-changed"
export const COMPONENT_ATTRIBUTE_CHANGED_EVENT = "component-attribute-changed"
export const COMPONENT_STYLE_CHANGED_EVENT = "component-style-changed"

export interface ComponentSelectedEvent {
  screenId: string
  parent: IPlatmaAppComponent
  config: IPlatmaAppComponent
}

export interface ComponentMovedEvent {
  screenId: string
  oldParent: IPlatmaAppComponent|null
  newParent: IPlatmaAppComponent|null
  config: IPlatmaAppComponent
}

export interface ComponentChangedEvent {
  screenId: string
  config: IPlatmaAppComponent
  parent: IPlatmaAppComponent|null
}

export interface ComponentInsertedEvent {
  screenId: string
  config: IPlatmaAppComponent
  parent: IPlatmaAppComponent|null
}

export interface ComponentDeletedEvent {
  screenId: string
  componentId: string
  parent: IPlatmaAppComponent|null
}
