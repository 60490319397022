import {
  ComponentService,
  IPlatmaAppComponent,
  PlRender,
  QueryService,
} from '@mf-ide/common';

export function RunQuery(
  render: PlRender,
  screen: IPlatmaAppComponent,
  component: ComponentService,
  params: { queryId: string },
) {
  const doRun = () => {
    const q = QueryService.Get(params.queryId);
    if (q) {
      q.Run();
    }
  };

  let interval = setInterval(() => {
    if (QueryService.IsInitialized()) {
      doRun();
      clearInterval(interval);
    }
  }, 100);
}
