import { ComponentService, ScreenService } from './';
import { IComponentsBundleItem } from '../interfaces/bundles/IComponentsBundle';
import { IPlatmaAppComponent } from '../interfaces/component/IPlatmaAppComponent';
import { PlatmaRegistry } from '../Registry';

declare var PLATMA_REGISTRY: PlatmaRegistry;

export class BaseLayer {
  public component: ComponentService | undefined;
  public screen: ScreenService | undefined;

  constructor(
    public screenId: string,
    public structure: IPlatmaAppComponent,
    public config: IComponentsBundleItem,
  ) {}

  protected Delete() {
    delete this.component;
    delete this.screen;
  }

  public SetComponentService(c: ComponentService) {
    this.component = c;
  }

  public SetScreenService(s: ScreenService) {
    this.screen = s;
  }

  public DeleteChild(id: string) {
    if (this.component) {
      const ci = this.component?.structure.children?.findIndex(
        (c) => c.id === id,
      );
      if (ci !== undefined && ci >= 0) {
        this.component.structure.children?.splice(ci, 1);
      }
    } else if (this.screen) {
      if (this.screen.structure && this.screen.structure.children) {
        const ci = this.screen.structure.children?.findIndex(
          (c) => c.id === id,
        );
        if (ci !== undefined && ci >= 0) {
          this.screen.structure.children.splice(ci, 1);
        }
      }
    }
  }

  public SetConfigAttribute(name: string, value: string) {
    if (this.component && this.component.structure) {
      if (!this.component.structure.attributes) {
        this.component.structure.attributes = {};
      }
      if (value.trim()) {
        this.component.structure.attributes[name] = value;
      } else {
        delete this.component.structure.attributes[name];
      }
    }
  }

  public SetConfigContent(content:string) {
    if(this.component&&this.component.structure) {
      this.component.structure.innerHtml = content
    }
  }

  public HasConfigAttribute(name: string) {
    return (
      this.component &&
      this.component.structure.attributes &&
      name in this.component.structure.attributes
    );
  }

  public GetConfigAttribute(name: string, defaultValue: string) {
    if (
      this.component &&
      this.component.structure.attributes &&
      name in this.component.structure.attributes
    ) {
      return this.component.structure.attributes[name];
    }
    return defaultValue;
  }

  public SetConfigStyle(name: string, value: string) {
    if (this.component && this.structure) {
      if (!this.structure.style) {
        this.structure.style = {};
      }
      if (value) {
        this.structure.style[name] = value;
      } else {
        delete this.structure.style[name];
      }
    }
  }

  public SetConfigStyles(style: { [index: string]: string } = {}) {
    if (style && this.structure) {
      this.structure.style = style;
    }
  }

  public GetStyle(name: string, defaultValue: string) {
    if (
      this.component &&
      this.component.structure.style &&
      name in this.component.structure.style
    ) {
      return this.component.structure.style[name];
    }
    return defaultValue;
  }

  protected applyDefaults(config: IPlatmaAppComponent): IPlatmaAppComponent {
    const bundleComponent = PLATMA_REGISTRY.GetAllComponents().find(
      (b) => b.id === config.id,
    );
    if (config && bundleComponent && bundleComponent.defaults) {
      config.attributes = { ...bundleComponent.defaults.attributes };
      config.innerHtml = bundleComponent.defaults.innerHtml;
      if (bundleComponent.children) {
        config.children = [...bundleComponent.children];
      }
    }
    return config;
  }
}
