import {
  AppService,
  ComponentService,
  IPlatmaAppComponent,
  PlRender,
} from '@mf-ide/common';

export function NavigateToUrl(
  render: PlRender,
  screen: IPlatmaAppComponent,
  component: ComponentService,
  params: { pageId: string },
) {
  if (!render) return;
  if (!render.app) return;
  const s = AppService.getScreen(params.pageId);
  if (s) {
    const ss = s.settings;
    if (ss) {
      render.app.Navigate(ss.slug);
    }
  }
}
