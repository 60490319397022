import { IPlatmaAppComponent } from '../interfaces/component/IPlatmaAppComponent';
import { IPlatmaAppScreenSettings } from '../interfaces/screen/IPlatmaAppScreenSettings';
import { pubScreenSettingsUpdated } from '../events';
import { IPlRenderCallback } from '../interfaces/IPlRenderCallback';
import { ComponentService } from './';

export class ScreenService extends ComponentService {
  private readonly componentVariables: { [index: string]: any } = {};
  public elementCreatedCallback: IPlRenderCallback | undefined;
  public elementRenderCallback: IPlRenderCallback | undefined;
  public elementDeleteCallback: IPlRenderCallback | undefined;
  private selectedComponentId: string | null = null;

  constructor(structure: IPlatmaAppComponent) {
    super(structure.id, structure, null);
    this.SetScreenService(this);
    this.GetComponentVariables();
  }

  public SetSelectedComponentId(id: string | null) {
    this.selectedComponentId = id;
  }

  public GetSelectedComponent(): ComponentService | null {
    if (this.selectedComponentId) {
      return this.Component(this.selectedComponentId);
    }
    return null;
  }

  private getFlatListRecursive(cs: IPlatmaAppComponent[]): string[] {
    let res: string[] = [];
    cs.forEach((c) => {
      res.push(c.id);
      if (c.children && c.children.length > 0) {
        res = [...res, ...this.getFlatListRecursive(c.children)];
      }
    });
    return res;
  }

  public GetAllComponentsFlatList(): string[] {
    if (this.structure.children) {
      return this.getFlatListRecursive(this.structure.children);
    }
    return [];
  }

  public UpdateScreenSettings(settings: IPlatmaAppScreenSettings) {
    this.structure.settings = settings;
    pubScreenSettingsUpdated({ screenId: this.structure.id, settings });
  }

  public Component(id: string): ComponentService | null {
    if (id === this.id) return this;
    let c;
    this.children.forEach((cc) => {
      const ccc = cc.Find(id);
      if (ccc) {
        c = ccc;
      }
    });
    if (!c) {
      console.error(`Component ${id} not found`);
      return null;
    }
    return c;
  }

  public GetComponentVariables(components?: ComponentService[]): {
    [index: string]: any;
  } {
    if (!components) {
      components = this.children;
    }
    components.forEach((c) => {
      if (c.id) {
        this.componentVariables[c.id] = c.GetVariables();
        if (c.children && c.children.length) {
          this.GetComponentVariables(c.children);
        }
      }
    });
    return this.componentVariables;
  }
}
