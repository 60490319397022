import { IPlatmaAppComponent } from '../../interfaces/component/IPlatmaAppComponent';
import { ComponentService } from '../../services';
import { PlRender } from '../../render';

export function ShowAlertActionHandler(
  render: PlRender,
  screen: IPlatmaAppComponent,
  component: ComponentService,
  params: {
    title: string;
    content: string;
    position: string;
    duration: number;
  },
) {
  const alert: any = document.createElement('pl-alert');
  alert.innerHTML = params.content;
  if (params.title) {
    alert.setAttribute('title', params.title);
  }
  alert.setAttribute('closable', 'true');
  alert.setAttribute('position', params.position);
  alert.toast();
}
