import { OpenModal } from './handlers/open-modal';
import { CloseModal } from './handlers/close-modal';
import { NavigateToUrl } from './handlers/navigate-to-url';
import { ControlComponentHandler } from './handlers/control-component-handler';
import { PlatmaRegistry } from '../Registry';
import { RunQuery } from './handlers/run-query';
import { SetVariableActionHandler } from './handlers/set-variable';
import { RunJsHandler } from './handlers/run-js.handler';
import { ShowAlertActionHandler } from './handlers/show-alert';

declare const PLATMA_REGISTRY: PlatmaRegistry;

PLATMA_REGISTRY.RegisterActionBundle({
  name: "Default",
  actions: [
    {
      id: "close-modal",
      name: "Close modal",
      handler: CloseModal
    },
    {
      id: "control-component",
      name: "Control component",
      handler: ControlComponentHandler
    },
    {
      id: "navigate-to-url",
      name: "Go to page",
      handler:NavigateToUrl
    },
    {
      id: "open-modal",
      name: "Open modal",
      handler: OpenModal
    },
    {
      id: "run-query",
      name: "Run Query",
      handler: RunQuery
    },
    {
      id: "set-variable",
      name: "Set variable",
      handler:SetVariableActionHandler
    },
    {
      id: 'run-js',
      name: 'Run Javascript',
      handler: RunJsHandler
    },
    {
      id: 'show-alert',
      name: 'Show Alert',
      handler: ShowAlertActionHandler
    },
  ]
})
