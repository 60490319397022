export class InternalVanillaRouter {
  constructor(
    private container: HTMLElement,
    private navigateChangeCallback: (pathname: string) => void,
  ) {
    addEventListener('popstate', (event) => {
      navigateChangeCallback(window.location.pathname);
    });
  }

  public GoTo(url: string) {
    if(!url) return
    if(!url.startsWith("/")) url = "/"+url
    window.history.pushState({}, url, window.location.origin + url);
    this.navigateChangeCallback(window.location.pathname);
  }

  PageNotFound() {
    this.container.innerHTML = `
<h1 style="text-align: center">404</h1>
<p style="text-align: center">Page not found</p>
<p style="text-align: center">Make sure you have application default page set</p>
`;
  }
}
