import { IPlatmaAppComponent } from '../../interfaces/component/IPlatmaAppComponent';
import { AppService, ComponentService } from '../../services';
import {
  PLATMA_COMPONENT_ACTIONS_RUN_METHOD,
  PLATMA_COMPONENT_ACTIONS_SET_ATTRIBUTE,
  PLATMA_COMPONENT_ACTIONS_SET_STYLE,
  PLATMA_COMPONENT_ACTIONS_SET_TEXT,
} from '../../constants';
import { PlRender } from '../../render';

export function ControlComponentHandler(
  render: PlRender,
  screen: IPlatmaAppComponent,
  component: ComponentService,
  params: {
    componentId: string;
    action: string;
    name: string;
    value: string;
    method: string;
    method_params: { [index: string]: any };
  },
) {
  const s = AppService.getScreen(screen.id);
  if (!s) return;
  const c = s.Component(params.componentId);
  if (!c) return;
  switch (params.action) {
    case PLATMA_COMPONENT_ACTIONS_SET_TEXT:
      c.SetContent(params.value);
      break;
    case PLATMA_COMPONENT_ACTIONS_SET_ATTRIBUTE:
      c.SetAttribute(params.name, params.value);
      break;
    case PLATMA_COMPONENT_ACTIONS_SET_STYLE:
      c.SetStyle(params.name, params.value);
      break;
    case PLATMA_COMPONENT_ACTIONS_RUN_METHOD:
      c.RunMethod(params.method, params.method_params);
  }
}
