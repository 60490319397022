import {
  ComponentService,
  IPlatmaAppComponent,
  PlRender,
} from '@mf-ide/common';

export function CloseModal(
  render: PlRender,
  screen: IPlatmaAppComponent,
  component: ComponentService,
  params: { modalId: string },
  data?: any,
  data_index?: number,
) {
  render.closeModal(params.modalId);
}
