export function proxify(
  object:any,
  change:(object:any,name:string, old:any, value:any)=>void,
  prefix: string = ""
) {
  const proxy = new Proxy(object, {
    get: function (object, name) {
      return object[name];
    },
    set: function (object, name, value) {
      const old = object[name];
      if (value && typeof value == 'object') {
        const nn = (prefix?prefix+".":"")+name.toString()
        value = proxify(value, change, nn);
      }
      object[name] = value;
      if(Array.isArray(object)) {
        if(name != "length") {
          change(object, prefix, old, {...object});
        }
      } else {
        const nn = (prefix?prefix+".":"")+name.toString()
        change(object, nn, old, value);
      }
      return true;
    },
  });
  for (let prop in object) {
    if (
      object.hasOwnProperty(prop) &&
      object[prop] &&
      typeof object[prop] == 'object'
    ) {
      const nn = (prefix?prefix+".":"")+prop.toString()
      object[prop] = proxify(object[prop], change, nn);
    }
  }
  return proxy;
}