import { AppService } from '../services';
import { dc, de } from './tools';

//language=css
const css = `
    pl-default-layout-header {
        display: flex;
        flex-direction: row;
        height: 3rem;
        position: relative;
    }

    pl-default-layout-header > figure {
        margin: 0;
        padding: 0;
        text-align: center;
    }
    
    pl-default-layout-header > figure[burger] {
        display: none;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        position: absolute;
        top: 0;
        left: 0;
    }

    pl-default-layout-header > figure[burger] > svg {
        color:var(--pl-color-text);
        width: 2rem;
        height: 2rem;
    }

    pl-default-layout-header > h2 {
        margin: auto;
        font-weight: 300;
    }

    pl-default-layout-header > pl-button {
        position: absolute;
        top:0;
        right: 0;
        width: 3rem;
        height: 3rem;
        font-size: 2rem;
        padding: .5rem;
    }

    @media (max-width: 768px) {
        pl-default-layout-header > figure[logo] {
            display: none;
        }

        pl-default-layout-header > figure[burger] {
            display: flex;
        }
    }
`;

export class PlDefaultLayoutHeader extends HTMLElement {
  private readonly logo: HTMLElement;
  private readonly burger: HTMLElement;
  private readonly h1: HTMLElement;
  private readonly themeSwitch: HTMLElement;
  private readonly styles: HTMLElement;

  public SetHasSidebar(show: boolean) {
    this.logo.style.width = show ? '200px' : '';
  }

  constructor() {
    super();
    this.burger = dc('figure');
    this.burger.setAttribute('burger', '');
    this.logo = dc('figure');
    this.logo.setAttribute('logo', '');
    this.themeSwitch = dc('pl-button');
    this.h1 = dc('h2');
    this.styles = dc('style');
    this.styles.innerHTML = css;
  }

  // noinspection JSUnusedGlobalSymbols
  connectedCallback() {
    const appSettings = AppService.GetSettings();
    this.burger.addEventListener("click", this.burgerHandler)

    this.h1.innerHTML = AppService.GetName();

    let colorScheme = 'light';
    if (appSettings.colorScheme?.autoDetect) {
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        colorScheme = 'dark';
      }
    }

    de.setAttribute('pl-color-scheme', colorScheme);
    de.setAttribute(
      'data-mantine-color-scheme',
      colorScheme,
    );

    const updateThemeIcon = () => {
      this.themeSwitch.setAttribute(
        'icon',
        'bi bi-' + (colorScheme === 'light' ? 'moon' : 'sun'),
      );
    };

    updateThemeIcon();

    setTimeout(() => {
      if (de.dataset.mantineColorScheme) {
        colorScheme = de.dataset.mantineColorScheme;
      }
      updateThemeIcon();
    }, 10);

    this.themeSwitch.setAttribute('variant', 'transparent');

    this.themeSwitch.addEventListener('click', () => {
      colorScheme = colorScheme === 'light' ? 'dark' : 'light';
      de.setAttribute('pl-color-scheme', colorScheme);
      de.setAttribute(
        'data-mantine-color-scheme',
        colorScheme,
      );
      de.dataset.mantineColorScheme = colorScheme;
      updateThemeIcon();
    });

    //language=svg
    this.logo.innerHTML = `
        <svg width="48" height="48" viewBox="0 0 22 22">
            <g transform="translate(-39.865639,4.3270381)">
                <path
                        d="m 60.228431,11.672962 h 1.637208 L 53.760409,0.6729619 50.865691,4.6015265 47.970973,0.6729619 39.865639,11.672962 H 58.391998 L 57.234049,10.101523 H 54.918254 L 51.685486,5.7140966 53.760409,2.898099 Z m -17.567635,-1.571439 5.310177,-7.2065647 2.074922,2.8159976 -3.232871,4.3905671 z m 10.62025,0 h -4.830711 l 2.415356,-3.2780069 z"
                        fill="var(--pl-primary-color-filled)"
                />
            </g>
        </svg>
    `;

    //language=svg
    this.burger.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list"
             viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
        </svg>
    `;

    this.append(this.styles);
    this.append(this.burger);
    this.append(this.logo);
    this.append(this.h1);
    if (
      appSettings.colorScheme?.light?.enabled &&
      appSettings.colorScheme.dark?.enabled
    ) {
      this.append(this.themeSwitch);
    }
  }

  // noinspection JSUnusedGlobalSymbols
  disconnectedCallback() {
    this.burger.removeEventListener("click", this.burgerHandler)
  }

  private burgerHandler = () => {
    this.dispatchEvent(new CustomEvent("toggle-sidebar"))
  }
}

if (!customElements.get('pl-default-layout-header')) {
  customElements.define('pl-default-layout-header', PlDefaultLayoutHeader);
}
