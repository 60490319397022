import {
  IActionsBundle,
  IActionsBundleItem,
} from './interfaces/bundles/IActionsBundle';
import {
  IIconsBundle,
  IIconsBundleIcon,
} from './interfaces/bundles/IIconsBundle';
import {
  IComponentsBundle,
  IComponentsBundleItem,
} from './interfaces/bundles/IComponentsBundle';
import {
  IConfiguratorConfig,
  IConfiguratorsBundle,
} from './interfaces/bundles/IConfiguratorsBundle';
import { IExtension } from './interfaces/extensions/IExtension';
import { IExtensionDrawer } from './interfaces/extensions/IExtensionDrawer';
import { IExtensionEditor } from './interfaces/extensions/IExtensionEditor';
import { IDataSource } from './interfaces/datasources/IDataSource';

export class PlatmaRegistry {
  private actionBundles: IActionsBundle[] = [];
  private iconBundles: IIconsBundle[] = [];
  private componentBundles: IComponentsBundle[] = [];
  private configuratorBundles: IConfiguratorsBundle[] = [];
  private datasources: IDataSource[] = [];
  private drawers: IExtensionDrawer[] = [];
  private editors: IExtensionEditor[] = [];

  extensions: IExtension[] = [];

  constructor() {}

  public RegisterExtension(extension: IExtension) {
    this.extensions.push(extension);
    if (extension.actions) {
      this.RegisterActionBundle(extension.actions);
    }
    if (extension.icons) {
      this.RegisterIconBundle(extension.icons);
    }
    if (extension.datasource) {
      this.datasources.push(extension.datasource);
    }
    if (extension.drawers) {
      extension.drawers.forEach((d) => this.drawers.push(d));
    }
    if (extension.editors) {
      extension.editors.forEach((e) => this.editors.push(e));
    }
  }

  public GetAllExtensions(): IExtension[] {
    return this.extensions;
  }

  public GetAllDataSources(): IDataSource[] {
    return this.datasources;
  }

  public GetAllEditors(): IExtensionEditor[] {
    return this.editors;
  }

  public RegisterEditor(editor: IExtensionEditor) {
    this.editors.push(editor);
  }

  public RegisterActionBundle(actionBundle: IActionsBundle) {
    this.actionBundles.push(actionBundle);
  }

  public GetAllActions(): IActionsBundleItem[] {
    let r: IActionsBundleItem[] = [];
    this.actionBundles.forEach((b) => {
      r = [...r, ...b.actions];
    });
    return r;
  }

  public RegisterIconBundle(iconsBundle: IIconsBundle) {
    this.iconBundles.push(iconsBundle);
  }

  public GetAllIconBundles(): IIconsBundle[] {
    return this.iconBundles;
  }

  public GetAllIcons(): IIconsBundleIcon[] {
    let r: IIconsBundleIcon[] = [];
    this.iconBundles.forEach((b) => {
      r = [...r, ...b.icons];
    });
    return r;
  }

  public RegisterComponentsBundle(bundle: IComponentsBundle) {
    this.componentBundles.push(bundle);
  }

  public GetAllComponents(): IComponentsBundleItem[] {
    let r: IComponentsBundleItem[] = [];
    this.componentBundles.forEach((b) => {
      r = [...r, ...b.components];
    });
    return r;
  }

  public GetComponent(id: string): IComponentsBundleItem | undefined {
    let cc: IComponentsBundleItem | undefined = undefined;
    this.componentBundles.forEach((b) => {
      const c = b.components.find((c) => c.id === id);
      if (c) {
        cc = c;
      }
    });
    return cc;
  }

  public PatchComponent(id: string, config: IComponentsBundleItem) {
    this.componentBundles.forEach((b) => {
      for (let i = 0; i < b.components.length; i++) {
        if (b.components[i].id === id) {
          b.components[i] = config;
        }
      }
    });
  }

  public RegisterConfiguratorBundle(bundle: IConfiguratorsBundle) {
    this.configuratorBundles.push(bundle);
  }

  public GetAllConfigurators(): IConfiguratorConfig[] {
    let r: IConfiguratorConfig[] = [];
    this.configuratorBundles.forEach((b) => {
      r = [...r, ...b.configurators];
    });
    return r;
  }
}

if (!("PLATMA_REGISTRY" in window)) {
  (window as any).PLATMA_REGISTRY = new PlatmaRegistry()
}
