export class BroadcastService {
  private static instance:BroadcastService|undefined

  private uuid: string
  private channel:BroadcastChannel|undefined = undefined

  constructor() {
    this.uuid = crypto.randomUUID()
    if("BroadcastChannel" in window) {
      this.channel = new BroadcastChannel("platma");
    }
  }

  public static PublishMessage(event:string,data:any) {
    if(this.instance&&this.instance.channel) {
      this.instance.channel.postMessage({uuid:this.instance.uuid,event,data})
    }
  }

  public static Init() {
    this.instance = new BroadcastService()
  }

  public static GetChannel() {
    return this.instance?.channel
  }

  public static IsOwnMessage(uuid:string) {
    return uuid == this.instance?.uuid
  }
}
