import { AppService, IPlatmaApp, PlApp } from '@mf-ide/common';
import '@mf-ide/components';
import './index.scss';

declare var app: IPlatmaApp;

AppService.InitStatic(app);
AppService.SetName("ToDo App")
const plApp = (document.getElementById("pl-app") as PlApp);
plApp.Init();
plApp.Navigate(window.location.pathname);