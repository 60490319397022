export const QUERY_UPDATED = 'query-updated'
export const QUERIES_LOADED = "queries-loaded"

export const QUERY_SAVE_REQUEST = "query-save-request"
export const QUERY_SAVING = "query-saving"
export const QUERY_SAVED = "query-saved"
export const QUERY_SAVING_FAILURE = "query-saving-failure"

export const QUERY_EXECUTE_REQUEST = "query-execute-request"
export const QUERY_EXECUTE_SUCCESS = "query-execute-success"
export const QUERY_EXECUTE_FAILURE = "query-execute-failure"

export const QUERY_ACTIVATE_REQUEST = "query-activate-request"
export const QUERY_ACTIVATED = "query-activated"