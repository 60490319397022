import {
  IQuery,
  IQueryExecuteFailure,
  IQueryExecuteSuccess,
  IQuerySavingFailure,
} from '../interfaces/query/IQuery';
import {
  publishEvent,
  pubVariableSet,
  QUERY_EXECUTE_FAILURE,
  QUERY_EXECUTE_SUCCESS,
  QUERY_SAVED,
  QUERY_SAVING,
  QUERY_UPDATED,
} from '../events';
import { ApiService } from './ApiService';
import { proxify } from './proxify';

export class QueryService {
  private static initialized = false;
  private static services: QueryService[] = [];
  private readonly variables: { [index: string]: any };

  private constructor(public query: IQuery) {
    this.variables = proxify(
      {
        id: query.id,
        kind: query.kind,
        data: {},
        rawData: {},
      },
      (_: any, name: string, oldValue: any, newValue: any) => {
        pubVariableSet({
          level: 'query',
          screenId: null,
          componentId: null,
          name: name,
          value: newValue,
        });
      },
    );
    this.variables.isLoading = false;
  }

  /*
   * STATIC
   */

  public static IsInitialized() {
    return QueryService.initialized;
  }

  public static Init(queries: IQuery[]) {
    QueryService.services = queries.map((q) => new QueryService(q));
    QueryService.initialized = true;
  }

  public static GetAll(): QueryService[] {
    if (!QueryService.services) {
      return [];
    }
    return QueryService.services;
  }

  public static Get(id: string): QueryService | undefined {
    return QueryService.services.find((s) => s.query.id == id);
  }

  public static GetVariables(): any {
    const res: { [index: string]: any } = {};
    this.GetAll().forEach((qs) => (res[qs.query.name] = qs.variables));
    return res;
  }

  /*
   * Instance methods
   */

  public setName(name: string) {
    this.query.name = name;
    publishEvent<IQuery>(QUERY_UPDATED, this.query);
  }

  public setURL(method: string, url: string) {
    this.query.options.method = method;
    this.query.options.url = url;
    publishEvent<IQuery>(QUERY_UPDATED, this.query);
  }

  public setBody(body_toggle: boolean, body: string[][], jsonBody: string) {
    this.query.options.body_toggle = body_toggle;
    this.query.options.body = body;
    this.query.options.json_body = jsonBody;
    publishEvent<IQuery>(QUERY_UPDATED, this.query);
  }

  public setHeaders(headers: string[][]) {
    this.query.options.headers = headers;
    publishEvent<IQuery>(QUERY_UPDATED, this.query);
  }

  public setUrlParams(url_params: string[][]) {
    this.query.options.url_params = url_params;
    publishEvent<IQuery>(QUERY_UPDATED, this.query);
  }

  public Run() {
    const url = this.query.options.url;
    if (!url) {
      return;
    }
    this.variables.isLoading = true;
    fetch(url, {
      method: this.query.options.method,
    })
      .then((r) => r.json())
      .then((result) => {
        this.variables.isLoading = false;
        this.variables.data = result;
        this.variables.rawData = result;
        publishEvent<IQueryExecuteSuccess>(QUERY_EXECUTE_SUCCESS, {
          result,
          query: this.query,
        });
      })
      .catch((error) => {
        this.variables.isLoading = false;
        this.variables.error = error;
        publishEvent<IQueryExecuteFailure>(QUERY_EXECUTE_FAILURE, {
          error,
          query: this.query,
        });
      });
  }

  public runQuery(mode: 'preview' | 'run', query: IQuery) {
    const url = query.options.url;
    if (!url) {
      return;
    }
    this.variables.isLoading = true;
    fetch(url, {
      method: query.options.method,
    })
      .then((r) => r.json())
      .then((result) => {
        this.variables.isLoading = false;
        this.variables.data = result;
        this.variables.rawData = result;
        publishEvent<IQueryExecuteSuccess>(QUERY_EXECUTE_SUCCESS, {
          result,
          query,
        });
      })
      .catch((error) => {
        this.variables.isLoading = false;
        this.variables.error = error;
        publishEvent<IQueryExecuteFailure>(QUERY_EXECUTE_FAILURE, {
          error,
          query,
        });
      });
  }

  public saveQuery(): Promise<IQuery> {
    publishEvent<IQuery>(QUERY_SAVING, this.query);
    return new Promise<IQuery>((resolve, reject) => {
      ApiService.SaveQuery(this.query)
        .then((r) => {
          publishEvent<IQuery>(QUERY_SAVED, r.data);
          resolve(r.data);
        })
        .catch((error) => {
          publishEvent<IQuerySavingFailure>(QUERY_SAVED, {
            error,
            query: this.query,
          });
          reject(error);
        });
    });
  }
}
